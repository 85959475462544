import { browser } from "$app/environment"
import { get } from "svelte/store"

import LL, { locale, setLocale } from "$i18n/i18n-svelte"
import type { Locales } from "$i18n/i18n-types"
import { loadLocaleAsync } from "$i18n/i18n-util.async"
import type { RequestEvent } from "@sveltejs/kit"
import { locales } from "$i18n/i18n-util"
import { detectLocale } from "typesafe-i18n/detectors"
import posthog from "posthog-js"

/* ============================================================================================== */

export default LL

/* ============================================================================================== */

/** The header under which the browser send the default language of the user. */
export const LOCALE_HEADER_KEY: string = "accept-language"
/** In this cookie the user can define their prefered language. */
export const LOCALE_COOKIE_KEY: string = "lang"
/** The default language to fall back to. */
export const LOCALE_FALLBACK: Locales = "en"

const REGEX_ACCEPT_LANGUAGE_SPLIT = /;|,/

/* ============================================================================================== */

/** Sets a locale preference (cookie), and changes the langage on site. */
export async function dynamicSwitchLocale(lang: Locales) {
    console.info("Setting language to", lang.toUpperCase())
    if (lang === get(locale)) return

    posthog.capture("switch language", { lang })
    posthog.setPersonProperties({ lang })

    // set cookie
    if (browser) {
        document.cookie = `${LOCALE_COOKIE_KEY}=${lang}`
    }

    // load+set locale
    await loadLocaleAsync(lang)
    setLocale(lang)

    // update dom / state
    if (browser) {
        document.querySelector("html")!.setAttribute("lang", lang)
    }
    // net necessary, because I do not use `locale` in load functions
    // invalidateAll()
}

/* ============================================================================================== */

/** Detect what language to use base on cookies and `Accept-Language` header`. */
export const detectLocaleServer = (event: RequestEvent) => {
    function preferedLanguage() {
        const cookieLang = event.cookies.get(LOCALE_COOKIE_KEY)
        return cookieLang !== undefined && locales.includes(cookieLang as any) ? [cookieLang] : []
    }

    function defaultLanguage() {
        const headerLangs = event.request.headers.get(LOCALE_HEADER_KEY)
        return (
            headerLangs
                ?.split(REGEX_ACCEPT_LANGUAGE_SPLIT)
                .map((part) => part.trim())
                .filter((part) => !part.startsWith("q"))
                .filter((part) => part !== "*")
                .filter((part) => part !== "") ?? []
        )
    }

    const locale: Locales = detectLocale(
        LOCALE_FALLBACK,
        locales,
        preferedLanguage,
        defaultLanguage,
    )
    return locale
}

/** Detect what language to use based on cookies and browser settings. */
export const detectLocaleBrowser = () => {
    function preferedLanguage() {
        const lang = document.cookie
            .split(";")
            .map((part) => part.trim())
            .find((part) => part.startsWith(LOCALE_COOKIE_KEY))
            ?.split("=")[1]

        return lang !== undefined ? [lang] : []
    }

    function defaultLanguage() {
        // because `navigator.languages` is read-only
        return [...navigator.languages]
    }

    const locale: Locales = detectLocale(
        LOCALE_FALLBACK,
        locales,
        preferedLanguage,
        defaultLanguage,
    )
    return locale
}
